var exports = {};
exports = bbcode;
bbcode.displayName = "bbcode";
bbcode.aliases = ["shortcode"];

function bbcode(Prism) {
  Prism.languages.bbcode = {
    tag: {
      pattern: /\[\/?[^\s=\]]+(?:\s*=\s*(?:"[^"]*"|'[^']*'|[^\s'"\]=]+))?(?:\s+[^\s=\]]+\s*=\s*(?:"[^"]*"|'[^']*'|[^\s'"\]=]+))*\s*\]/,
      inside: {
        tag: {
          pattern: /^\[\/?[^\s=\]]+/,
          inside: {
            punctuation: /^\[\/?/
          }
        },
        "attr-value": {
          pattern: /=\s*(?:"[^"]*"|'[^']*'|[^\s'"\]=]+)/i,
          inside: {
            punctuation: [/^=/, {
              pattern: /^(\s*)["']|["']$/,
              lookbehind: true
            }]
          }
        },
        punctuation: /\]/,
        "attr-name": /[^\s=\]]+/
      }
    }
  };
  Prism.languages.shortcode = Prism.languages.bbcode;
}

export default exports;